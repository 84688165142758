// src/App.js
import React from 'react';
import './styles/tailwind.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Layout from './components/Layout';
import ExhibitDetail from './pages/ExhibitDetail';
import Table from './pages/Table';
import Homepage from './pages/Homepage';
import './i18n';
import QRScanner from './pages/QRScanner';

function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/exhibits/:uuid" element={<ExhibitDetail />} />
                    <Route path="/exhibits" element={<Table />} />
                    <Route path="/" element={<Homepage />} />
                    <Route path="/scan" element={<QRScanner />} />

                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
