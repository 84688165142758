import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BrowserMultiFormatReader } from '@zxing/library';

function QRScanner() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [scanned, setScanned] = useState(false);
    const videoRef = useRef(null);
    const codeReader = useRef(new BrowserMultiFormatReader());

    // Memoize handleScan to prevent unnecessary effect re-runs
    const handleScan = useCallback((data) => {
        if (data && !scanned) {
            setScanned(true);
            console.log('Scanned data:', data);
            navigate(`/exhibits/${data}`);
        }
    }, [scanned, navigate]);

    // Memoize handleError to prevent unnecessary effect re-runs
    const handleError = useCallback((err) => {
        console.error('Scan error:', err);
    }, []);

    useEffect(() => {
        const currentCodeReader = codeReader.current; // Store the ref value in a local variable

        if (location.pathname !== '/scan') {
            setScanned(true); // Stop scanning when navigating away from /scan
            currentCodeReader.reset(); // Stop the camera
            return;
        }

        if (!scanned) {
            currentCodeReader
                .listVideoInputDevices()
                .then((devices) => {
                    if (devices.length > 0) {
                        const selectedDeviceId = devices[0].deviceId;
                        currentCodeReader
                            .decodeFromVideoDevice(selectedDeviceId, videoRef.current, (result, error) => {
                                if (result) {
                                    handleScan(result.getText());
                                }
                                if (error) {
                                    handleError(error);
                                }
                            });
                    }
                })
                .catch((err) => console.error(err));
        }

        return () => {
            currentCodeReader.reset(); // Cleanup on unmount or location change
        };
    }, [location.pathname, scanned, handleScan, handleError]);

    return (
        <div className="flex flex-col items-center justify-center h-screen p-4 bg-gray-100">
            <h1 className="text-4xl font-bold mb-6 text-center">{t('scanQRCode')}</h1>
            {location.pathname === '/scan' && (
                <div className="relative w-80 h-80 mb-6">
                    <video ref={videoRef} className="w-full h-full" />
                </div>
            )}
            <button 
                onClick={() => navigate('/exhibits')} 
                className="bg-light-yellow text-dark-blue py-2 px-4 rounded-lg shadow-lg hover:bg-teal transition-colors"
            >
                {t('goToExhibits')}
            </button>
        </div>
    );
}

export default QRScanner;
