import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchExhibitByUUID, fetchExhibitsByRoom } from '../services/Exhibit'; // Import the function

function ExhibitDetail() {
    const { uuid } = useParams(); // Extract uuid from URL parameters
    const [exhibit, setExhibit] = useState(null);
    const [additionalExhibits, setAdditionalExhibits] = useState([]); // State for additional exhibits
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loadingError, setLoadingError] = useState(false); // State to track loading errors
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(); // Destructure i18n from useTranslation

    useEffect(() => {
        const getExhibit = async () => {
            if (uuid) {
                try {
                    const data = await fetchExhibitByUUID(uuid);
                    if (data) {
                        console.log('Exhibit data:', data); // Log the fetched data
                        setExhibit(data);
                        if (data.room) {
                            const additionalData = await fetchExhibitsByRoom(data.room, uuid);
                            setAdditionalExhibits(additionalData);
                        }
                    } else {
                        setLoadingError(true); // Set error if no data is found
                    }
                } catch (error) {
                    console.error('Failed to fetch exhibit:', error);
                    setLoadingError(true); // Set error on fetch failure
                }
            } else {
                console.error('UUID is undefined');
                setLoadingError(true); // Set error if UUID is undefined
            }
        };

        getExhibit();
    }, [uuid, i18n.language]); // Add i18n.language to dependencies

    // Render loading error or loading text
    if (loadingError) {
        return (
            <div className="p-4 flex flex-col items-center justify-center h-screen bg-gray-100">
                <h2 className="text-2xl font-bold mb-4 text-dark-blue">{t('exhibitNotFound')}</h2>
                <button 
                    onClick={() => navigate('/scan')} 
                    className="bg-light-yellow text-dark-blue py-2 px-4 rounded-lg shadow-lg hover:bg-teal transition-colors"
                >
                    {t('scanAnotherCode')}
                </button>
            </div>
        );
    }

    // Safeguard rendering if exhibit is not available
    if (!exhibit) return <div className="p-4">{t('loading')}</div>;

    const { name, image, audio, description, room } = exhibit; // Destructure room from exhibit

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : image.length - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex < image.length - 1 ? prevIndex + 1 : 0));
    };

    return (
        <div className="p-6 max-w-4xl mx-auto bg-white shadow-xl rounded-lg relative">
            {/* Big X to exit */}
            <button
                onClick={() => navigate('/exhibits')}
                className="absolute top-4 right-4 text-4xl text-red-600 hover:text-red-800"
                aria-label="Close"
            >
                &#x2715;
            </button>
            
            <h2 className="text-4xl font-bold mb-2 text-dark-blue">{name || t('noName')}</h2>
            {room && (
                <p className="text-lg italic text-dark-blue mb-6">{t('room')}: {room}</p>
            )}
            
            {/* Gallery with navigation arrows */}
            {image && image.length > 0 && (
                <div className="relative mb-6">
                    <button
                        onClick={handlePrev}
                        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-dark-blue text-white p-3 rounded-full shadow-lg hover:bg-teal transition-colors"
                    >
                        &#9664;
                    </button>
                    <div className="flex justify-center">
                        <img
                            src={image[currentIndex]}
                            alt={`${name || 'Exhibit'}${currentIndex + 1}`}
                            className="w-full max-w-full h-80 object-contain rounded-lg shadow-md"
                        />
                    </div>
                    <button
                        onClick={handleNext}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-dark-blue text-white p-3 rounded-full shadow-lg hover:bg-teal transition-colors"
                    >
                        &#9654;
                    </button>
                </div>
            )}

            {/* Audio controls for multiple audio files */}
            {audio && audio.length > 0 && (
                <div className="mb-6">
                    {audio.map((aud, index) => (
                        <audio key={index} controls className="w-full mb-2 border border-gray-300 rounded-lg shadow-sm">
                            <source src={aud} type="audio/mpeg" />
                        </audio>
                    ))}
                </div>
            )}

            <p className="text-lg mb-6 text-dark-blue">{description || t('noDescription')}</p>
            <div className="flex gap-4 mb-6">
                <button
                    onClick={() => navigate('/exhibits')}
                    className="bg-light-yellow text-dark-blue py-2 px-4 rounded-lg shadow-lg hover:bg-teal transition-colors"
                >
                    {t('backToList')}
                </button>
                <button
                    onClick={() => navigate('/scan')}
                    className="bg-dark-blue text-white py-2 px-4 rounded-lg shadow-lg hover:bg-teal transition-colors"
                >
                    {t('scanAnotherCode')}
                </button>
            </div>

            {/* More exhibits from the same room */}
            <div>
                <h3 className="pt-10 pb-5 text-2xl font-bold text-dark-blue">{t('moreExhibitsFromRoom')}</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {additionalExhibits.map((exhibit) => (
                        <div key={exhibit.id} className="bg-gray-200 p-4 rounded-lg shadow-md">
                            <img src={exhibit.image[0]} alt={exhibit.name} className="w-full h-40 object-contain rounded-lg mb-2" />
                            <h4 className="text-lg font-bold mb-2">{exhibit.name}</h4>
                            <p className="text-sm">{exhibit.description ? `${exhibit.description.slice(0, 50)}...` : t('noDescription')}</p>
                            <button
                                onClick={() => navigate(`/exhibits/${exhibit.uuid}`)}
                                className="mt-2 bg-dark-blue text-white py-1 px-2 rounded-lg shadow-lg hover:bg-teal transition-colors"
                            >
                                {t('viewExhibit')}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ExhibitDetail;