import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Homepage() {
    const { t } = useTranslation(); // Use `t` for translations
    const navigate = useNavigate();

    return (
        <div className="p-8 max-w-4xl mx-auto bg-white shadow-lg rounded-lg relative overflow-hidden">
            {/* Decorative Elements */}
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute top-0 left-0 w-full h-full"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 200"
                    fill="none"
                >
                    <circle cx="50" cy="50" r="50" fill="#E9C46A" opacity="0.3" />
                    <circle cx="150" cy="150" r="70" fill="#F4A261" opacity="0.3" />
                </svg>
                <svg
                    className="absolute top-0 right-0 w-full h-full"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 200"
                    fill="none"
                >
                    <rect x="10" y="10" width="80" height="80" fill="#2A9D8F" opacity="0.3" />
                    <rect x="120" y="120" width="80" height="80" fill="#E76F51" opacity="0.3" />
                </svg>
            </div>

            {/* Logo Section */}
            <div className="flex justify-center mb-2">
                <img
                    src="/images/logo.svg" // Update with your logo path
                    alt="Project Logo"
                    className="w-auto h-48 object-contain"
                />
            </div>

            <div className="flex justify-center mb-8 gap-4">
                <button
                    onClick={() => navigate('/exhibits')}
                    className="bg-light-yellow text-dark-blue py-2 px-4 rounded-lg shadow-lg hover:bg-teal transition-colors"
                >
                    {t('exhibitList')}
                </button>
                <button
                    onClick={() => navigate('/scan')}
                    className="bg-dark-blue text-white py-2 px-4 rounded-lg shadow-lg hover:bg-teal transition-colors"
                >
                    {t('scanQRCode')}
                </button>
            </div>

            <h1 className="text-4xl text-center font-bold mb-6 text-dark-blue">{t('homepage.title')}</h1>
            <p className="text-lg mb-4">{t('homepage.introduction')}</p>
            <p className="text-lg mb-4">{t('homepage.projectObjectives')}</p>
            <ul className="list-disc list-inside text-lg mb-4 pl-6">
                <li>{t('homepage.objective1')}</li>
                <li>{t('homepage.objective2')}</li>
                <li>{t('homepage.objective3')}</li>
                <li>{t('homepage.objective4')}</li>
            </ul>
            <p className="text-lg mb-4">{t('homepage.activities')}</p>
            <p className="text-lg mb-6">{t('homepage.expectedImpact')}</p>
            <p className="text-lg mb-6">{t('homepage.invitation')}</p>
        </div>
    );
}

export default Homepage;
