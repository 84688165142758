import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchExhibits } from '../services/Exhibit'; // Import the new function

function ExhibitTable() {
    const [exhibits, setExhibits] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState('');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    // Fetch exhibits and rooms based on current locale
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchExhibits();
                setExhibits(data);

                // Extract unique rooms from the exhibit data
                const uniqueRooms = Array.from(new Set(data.map(exhibit => exhibit.room)))
                    .filter(room => room) // Remove any falsy values
                    .map(room => ({ id: room, name: room })); // Create an array of room objects
                setRooms(uniqueRooms);
            } catch (error) {
                console.error('Error fetching exhibits:', error);
            }
        };

        fetchData();
    }, [i18n.language]); // Depend on i18n.language to refetch data on locale change

    // Filter exhibits by the selected room
    const filteredExhibits = selectedRoom
        ? exhibits.filter(exhibit => exhibit.room === selectedRoom)
        : exhibits;

    return (
        <div className="p-4">
            <h1 className="text-xl font-bold mb-4">{t('exhibits')}</h1>
            <select
                onChange={e => setSelectedRoom(e.target.value)}
                value={selectedRoom}
                className="mb-4 p-2 border border-gray-300 rounded w-full sm:w-auto"
            >
                <option value="">{t('roomSelection')}</option>
                {rooms.map(room => (
                    <option key={room.id} value={room.id}>{room.name}</option>
                ))}
            </select>
            <div className="overflow-x-auto">
                <table className="min-w-full border-collapse hidden sm:table">
                    <thead>
                        <tr>
                            <th className="border px-4 py-2">{t('image')}</th>
                            <th className="border px-4 py-2">{t('name')}</th>
                            <th className="border px-4 py-2">{t('room')}</th>
                            <th className="border px-4 py-2">{t('excerpt')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredExhibits.length > 0 ? (
                            filteredExhibits.map(exhibit => (
                                <tr
                                    key={exhibit.id}
                                    onClick={() => navigate(`/exhibits/${exhibit.uuid}`)}
                                    className="cursor-pointer hover:bg-gray-100"
                                >
                                    <td className="border px-4 py-2">
                                        <img
                                            src={(exhibit.image && exhibit.image.length > 0) ? exhibit.image[0] : 'path/to/placeholder-image.jpg'}
                                            alt={exhibit.name}
                                            width="50"
                                            height="50"
                                            className="object-cover rounded"
                                        />
                                    </td>
                                    <td className="border px-4 py-2">{exhibit.name}</td>
                                    <td className="border px-4 py-2">{exhibit.room}</td>
                                    <td className="border px-4 py-2 truncate max-w-xs">
                                        {exhibit.description ? `${exhibit.description.slice(0, 50)}...` : t('noDescription')}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="border px-4 py-2 text-center">{t('noExhibits')}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {/* Mobile View */}
                <div className="block sm:hidden">
                    {filteredExhibits.length > 0 ? (
                        filteredExhibits.map(exhibit => (
                            <div
                                key={exhibit.id}
                                onClick={() => navigate(`/exhibits/${exhibit.uuid}`)}
                                className="border p-4 mb-4 rounded-lg shadow-sm hover:bg-gray-100 cursor-pointer"
                            >
                                <div className="flex items-center mb-4">
                                    <img
                                        src={(exhibit.image && exhibit.image.length > 0) ? exhibit.image[0] : 'path/to/placeholder-image.jpg'}
                                        alt={exhibit.name}
                                        width="100"
                                        height="100"
                                        className="object-cover rounded mr-4"
                                    />
                                    <div>
                                        <h2 className="text-lg font-bold">{exhibit.name}</h2>
                                        <p className="text-sm text-gray-600">{exhibit.room}</p>
                                    </div>
                                </div>
                                <p className="truncate">{exhibit.description ? `${exhibit.description.slice(0, 100)}...` : t('noDescription')}</p>
                            </div>
                        ))
                    ) : (
                        <div className="border p-4 mb-4 rounded-lg shadow-sm text-center">{t('noExhibits')}</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ExhibitTable;