// src/services/strapi.js
import CONFIG from '../config'; // Import the configuration file

export async function fetchFromAPI(endpoint) {
    try {
        const apiUrl = `${CONFIG.API_URL}/${endpoint}`;
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${CONFIG.STRAPI_TOKEN}`, // Include API Token in the Authorization header
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

